import type {
  GetServerSidePropsResult,
  GetStaticPropsContext,
  NextPage,
} from "next";
import { TemplateText, Text } from "src/presentation/components/atoms";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import styles from "./misc-pages.module.scss";
import { settings } from "@config/settings";
import { MainRouter } from "@config/urls";
import NotFoundIcon from "@assets/icons/not-found.svg";
import Link from "next/link";

const NotFoundPage: NextPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root_error}>
      <NotFoundIcon />
      <Text as="h2">{t("NotFoundView.heading")}</Text>
      <TemplateText template="normal">
        {t("NotFoundView.description")}
      </TemplateText>
      <Link legacyBehavior href={MainRouter.home.generatePath()}>
        <a>
          <p className="strong">{t("NotFoundView.link1")}</p>
        </a>
      </Link>
    </div>
  );
};

export async function getStaticProps(
  Props: GetStaticPropsContext
): Promise<GetServerSidePropsResult<any>> {
  const { locale } = Props;

  return {
    props: {
      ...(await serverSideTranslations(locale || settings.I18N_FALLBACK, [
        "common",
      ])),
    },
  };
}

export default NotFoundPage;
